import { t, Trans } from '@lingui/macro';
import { find, noop, uniq } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import { Button } from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Select from '../../../components/Select/Select';
import TagInput from '../../../components/TagInput/TagInput';
import {
  addToStringifiedArray,
  getParsedValue,
  getStringifiedValue,
} from '../../../components/TagInput/TagInput.util';
import useAnalytics from '../../../hooks/useAnalytics';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import useRole from '../../../hooks/useRole';
import useUser from '../../../hooks/useUser';
import {
  getAvailabilityGrammageOptions,
  getBaseWeightOptions,
  getBrandOptions,
  getBrands,
  getCaliperOptions,
  getFinishOptions,
  getGradeOptions,
  getParentBrandOptions,
  getTextureOptionsForProductAvailability,
} from '../../../services/Brand';
import {
  deriveCustomerOptions,
  getCustomerByCustomerNumber,
} from '../../../services/Customer';
import fractionOrDecimalInput from '../../../services/Util/fractionOrDecimalInput.util';
import { mediumWidthKwd } from '../../../services/Util/getWidthCssClass.util';
import { AuthorityRole } from '../../../types/Authority.interface';
import Brand from '../../../types/Brand.interface';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import Option from '../../../types/Option.type';
import ProductQuery from '../../../types/ProductQuery.interface';
import PutUpKwd from '../../../types/PutUpKwd.enum';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import Region from '../../../types/Region.enum';
import { IMP_REG_EX } from '../../../types/RegularExpression.constants';
import BookmarkedSkusModal from '../BookmarkedSkusModal/BookmarkedSkusModal';
import SkuLookupModal from '../SkuLookupModal/SkuLookupModal';
import useProductAvailabilityStore from '../useProductAvailabilityStore.store';
import ProductAvailabilityCheckboxInput from './ProductAvailabilityCheckboxInput';
import {
  isApplyFilterDisabled,
  prepSappiSkusOrMatlNos,
} from './ProductAvailabilityForm.util';
import ProductAvailabilityFormLeftHalf from './ProductAvailabilityFormLeftHalf';
import ProductAvailabilityFormRightHalf from './ProductAvailabilityFormRightHalf';
// import CustomerInformation from '../../../components/CustomerInformation/CustomerInformation';
// import { AvailabilityCustomer } from '../../../types/AvailabilityCustomer.interface';

interface IProductAvailabilityFormProp {
  applyProductQuery: (productQuery: ProductQuery) => void;
  onStockCheckboxChange: (visible: boolean) => void;
  onMillCheckboxChange: (visible: boolean) => void;
  onPlannedProductionCheckboxChange: (visible: boolean) => void;
  onOnlyAvailableItemsCheckboxChange: (visible: boolean) => void;
  setIsFilteredStocksSet: React.Dispatch<boolean>;
}

const ProductAvailabilityForm: React.FunctionComponent<IProductAvailabilityFormProp> = ({
  applyProductQuery,
  onStockCheckboxChange,
  onMillCheckboxChange,
  onPlannedProductionCheckboxChange,
  onOnlyAvailableItemsCheckboxChange,
  setIsFilteredStocksSet,
}) => {
  type FilterForm = {
    brand: string;
    finish: string;
    caliper: number;
    caliperMils: number;
    grammage: string;
    parentBrand: string;
    chemistry: string;
    texture: string;
    minWidth: number;
    maxWidth: number;
    minLength: number;
    maxLength: number;
    sappiSkus: string;
    sappiSkusValues: string;
  };
  const storedProductQuery = useProductAvailabilityStore();

  const {
    register,
    handleSubmit,
    reset,
    errors,
    watch,
    clearErrors,
    setValue,
    getValues,
    formState,
  } = useForm<FilterForm>({
    mode: 'all',
    defaultValues: {
      sappiSkusValues: getStringifiedValue(storedProductQuery.skuCodes),
    },
  });

  const { format } = useNumberFormatter();
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();
  const isEuUser = user?.region === Region.EU;
  const { isMetric } = useMeasurementSystem();
  const { hasRole, isNonReleaseEuUser } = useRole();
  const [selectedCustomer, setSelectedCustomer] = useState<string>();
  // const [selectedCustomerInfo, setSelectedCustomerInfo] = useState<
  //   AvailabilityCustomer
  // >();
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [applyFilterDisabled, setApplyFilterDisabled] = useState<boolean>(
    false
  );
  const [rollsChecked, setRollsChecked] = useState<boolean>(true);
  const [sheetsChecked, setSheetsChecked] = useState<boolean>(true);
  const [stockChecked, setStockChecked] = useState<boolean>(true);
  const [millInventoryChecked, setMillInventoryChecked] = useState<boolean>(
    true
  );
  const [plannedProductionChecked, setPlannedProductionChecked] = useState<
    boolean
  >(true);
  const [onlyAvailableItemsChecked, setOnlyAvailableItemsChecked] = useState<
    boolean
  >(false);
  const [skuLookupModalVisible, setSkuLookupModalVisible] = useState<boolean>(
    false
  );
  const [bookmarkedSkusModalVisible, setBookmarkedSkusModalVisible] = useState<
    boolean
  >(false);
  const [isFirstBrandsLoad, setIsFirstBrandLoad] = useState(true);
  const [showStock, setShowStock] = useState<boolean>(true);
  const [showMillInventory, setShowMillInventory] = useState<boolean>(true);
  const [showPlannedProduction, setShowProductionPlanning] = useState<boolean>(
    true
  );

  /**
   * Adds an array of Sappi SKUs to the array of Sappi SKUs, and dedupes the
   * resulting array of Sappi SKUs.
   */
  const addSappiSkus = (sappiSkusToAdd: string[]): void => {
    setValue(
      'sappiSkusValues',
      addToStringifiedArray(getValues().sappiSkusValues || '', sappiSkusToAdd),
      { shouldDirty: true }
    );
  };

  const clearAllSappiSkus = () => {
    reset({
      ...getValues(),
      sappiSkus: '',
      sappiSkusValues: '',
    });
  };

  useEffect(() => {
    if (user) {
      const { customers } = user;
      const derivedCustomerOptions = deriveCustomerOptions(
        customers || [],
        isNonReleaseEuUser,
        false,
        false
      );
      setCustomerOptions(derivedCustomerOptions);
      if (!selectedCustomer) {
        setSelectedCustomer(
          storedProductQuery.customerSoldTo ||
            (derivedCustomerOptions[0].value as string)
        );
      }

      const isShowStockFlagged = getCustomerByCustomerNumber(
        selectedCustomer,
        customers
      )?.showStock;

      const isShowMillInventoryFlagged = getCustomerByCustomerNumber(
        selectedCustomer,
        customers
      )?.showMillInventory;

      const isShowProductionPlanningFlagged = getCustomerByCustomerNumber(
        selectedCustomer,
        customers
      )?.showProductionPlanning;

      if (isShowStockFlagged !== undefined) {
        setShowStock(isShowStockFlagged);
      }

      if (isShowMillInventoryFlagged !== undefined) {
        setShowMillInventory(isShowMillInventoryFlagged);
      }

      if (isShowProductionPlanningFlagged !== undefined) {
        setShowProductionPlanning(isShowProductionPlanningFlagged);
      }
    }
  }, [user, isNonReleaseEuUser, hasRole, selectedCustomer, storedProductQuery]);

  useEffect(() => {
    trackPageView('PRODUCT_AVAILABILITY', 'STOCK_FILTER');
    onStockCheckboxChange(stockChecked);
  }, [stockChecked, onStockCheckboxChange, trackPageView, storedProductQuery]);

  useEffect(() => {
    trackPageView('PRODUCT_AVAILABILITY', 'MILL_FILTER');
    onMillCheckboxChange(millInventoryChecked);
  }, [millInventoryChecked, onMillCheckboxChange, trackPageView]);

  useEffect(() => {
    trackPageView('PRODUCT_AVAILABILITY', 'PRODPLAN_FILTER');
    onPlannedProductionCheckboxChange(plannedProductionChecked);
  }, [
    plannedProductionChecked,
    onPlannedProductionCheckboxChange,
    trackPageView,
  ]);

  useEffect(() => {
    trackPageView('PRODUCT_AVAILABILITY', 'AVAILABLE_FILTER');
    onOnlyAvailableItemsCheckboxChange(onlyAvailableItemsChecked);
  }, [
    onlyAvailableItemsChecked,
    onOnlyAvailableItemsCheckboxChange,
    trackPageView,
  ]);

  const { resolvedData: brands, isFetching } = usePaginatedQuery(
    [
      QueryCacheName.BRANDS,
      {
        customer: selectedCustomer,
        availabilityFlag: 'X',
      },
    ],
    getBrands,
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      enabled: selectedCustomer,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (isFirstBrandsLoad && brands?.length && customerOptions?.length) {
      setTimeout(() => {
        setValue('parentBrand', storedProductQuery.parentBrand);
        setValue('chemistry', storedProductQuery.chemistry);
        setValue('texture', storedProductQuery.texture);
        setValue('minWidth', storedProductQuery.minWidth);
        setValue('maxWidth', storedProductQuery.maxWidth);
        setValue('minLength', storedProductQuery.minLength);
        setValue('maxLength', storedProductQuery.maxLength);
      }, 0); // timeout allows watch to propagate options
      // These three fields depend on eachother so need to be set asynchronously separately to allow dropdowns to populate
      setTimeout(() => setValue('brand', storedProductQuery.brand), 0);
      setTimeout(() => setValue('finish', storedProductQuery.finish), 0);
      setTimeout(() => setValue('grammage', storedProductQuery.grammage), 0);
      setTimeout(() => setValue('caliper', storedProductQuery.caliper), 0);
      setTimeout(
        () => setValue('caliperMils', storedProductQuery.caliperInMils),
        0
      );
      setIsFirstBrandLoad(false);
    }
  }, [
    brands,
    storedProductQuery,
    setValue,
    isFirstBrandsLoad,
    customerOptions,
  ]);

  const form = watch();
  const brandWatch = watch('brand') as string;
  const finishWatch = watch('finish') as string;
  const basisWeightWatch = watch('basisWeight') as string;
  const caliperWatch = watch('caliper') as number;
  const caliperInMilsWatch = watch('caliperInMils') as number;
  const grammageWatch = watch('grammage') as string;
  const parentBrandWatch = watch('parentBrand') as string;
  const chemistryWatch = watch('chemistry') as string;
  const textureWatch = watch('texture') as string;
  const sappiSkusValues = watch('sappiSkusValues');

  const rollsPutUp = rollsChecked
    ? [{ putUp: PutUpKwd.ROLL }, { putUp: PutUpKwd.SHEETER_REEL }]
    : [];
  const sheetsPutUp = sheetsChecked ? [{ putUp: PutUpKwd.SHEET }] : [];

  useEffect(() => {
    setApplyFilterDisabled(
      isApplyFilterDisabled(form, getParsedValue(sappiSkusValues))
    );
  }, [brandWatch, parentBrandWatch, sappiSkusValues, form]);

  const formatCaliperOption = (option: Option, idx: number) => {
    if (idx === 0) {
      return option;
    }
    option.label = format(option.label as string);
    return option;
  };

  useEffect(() => {
    reset();
  }, [selectedCustomer, reset]);

  const onSubmitReset = () => {
    trackPageView('PRODUCT_AVAILABILITY', 'RESET_FORM');
    reset();
    setStockChecked(true);
    setPlannedProductionChecked(true);
    setMillInventoryChecked(true);
    setOnlyAvailableItemsChecked(false);
    clearAllSappiSkus();
    clearErrors();
  };

  const searchBookmarkedSkus = (sappiSkusToAdd: string[]): void => {
    const productQuery: ProductQuery = {
      customerSoldTo: selectedCustomer,
      putUps: !hasRole(AuthorityRole.ROLE_RELEASE)
        ? rollsPutUp.concat(sheetsPutUp)
        : [],
      skuCodes: uniq(sappiSkusToAdd),
      isAvailabilityPlanning: true,
    };

    applyProductQuery(productQuery);
  };

  const onSubmit = handleSubmit((data: FilterForm) => {
    trackPageView('PRODUCT_AVAILABILITY', 'SHOW_AVAILABILITY', {
      customerId: selectedCustomer,
    });
    const productQuery: ProductQuery = {
      ...data,
      customerSoldTo: selectedCustomer,
      qobFlag: undefined,
      putUps: !hasRole(AuthorityRole.ROLE_RELEASE)
        ? rollsPutUp.concat(sheetsPutUp)
        : [],
      skuCodes: prepSappiSkusOrMatlNos(getParsedValue(data.sappiSkusValues)),
      isAvailabilityPlanning: true,
      measurementSystem: isMetric
        ? MeasurementSystem.METRIC
        : MeasurementSystem.IMPERIAL,
    };

    if (!applyFilterDisabled) {
      applyProductQuery(productQuery);
    }
    setIsFilteredStocksSet(false);
  });

  const NonReleaseUserExperience = (
    <>
      {!isMetric && (
        <div className="pb-3">
          <InputLabel
            size="lg"
            isFetching={isFetching}
            labelClassName="justify-between mr-10"
            text={<Trans>Product</Trans>}
          >
            <Select
              options={getBrandOptions(brands as Brand[], {
                selectedCaliper: String(caliperWatch),
                selectedGrammage: basisWeightWatch,
                defaultSelectLabel: t`Select`,
              })}
              ref={register()}
              name="brand"
              disabled={!!sappiSkusValues}
            />
          </InputLabel>
        </div>
      )}
      {isMetric && (
        <div className="pb-3">
          <InputLabel
            size="lg"
            isFetching={isFetching}
            labelClassName="justify-between mr-10"
            text={<Trans>Product</Trans>}
          >
            <Select
              options={getBrandOptions(brands as Brand[], {
                selectedCaliperMils: String(caliperInMilsWatch),
                selectedGrammage: grammageWatch,
                defaultSelectLabel: t`Select`,
              })}
              ref={register()}
              name="brand"
              disabled={!!sappiSkusValues}
            />
          </InputLabel>
        </div>
      )}
      <div className="flex pb-4">
        <div className="w-1/2">
          <InputLabel
            size="lg"
            isFetching={isFetching}
            labelClassName="justify-between mr-10"
            text={<Trans>Finish</Trans>}
          >
            <Select
              options={getFinishOptions(
                brands as Brand[],
                brandWatch,
                t`Select`
              )}
              ref={register()}
              name="finish"
              disabled={!!sappiSkusValues}
            />
          </InputLabel>
        </div>
      </div>
      {!isMetric && (
        <div className="flex pb-4">
          <div className="w-1/2">
            <InputLabel
              size="lg"
              isFetching={isFetching}
              labelClassName="justify-between mr-10"
              text={<Trans>Caliper</Trans>}
            >
              <Select
                options={getCaliperOptions(brands as Brand[], {
                  selectedBrand: brandWatch,
                  selectedGrammage: grammageWatch,
                  defaultSelectLabel: t`Select`,
                  isMetric,
                })
                  .filter((o) => o.value !== 0)
                  .map(formatCaliperOption)}
                ref={register()}
                name="caliper"
                disabled={!!sappiSkusValues}
              />
            </InputLabel>
          </div>
        </div>
      )}
      {isMetric && (
        <div className="flex pb-4">
          <div className="w-1/2">
            <InputLabel
              size="lg"
              isFetching={isFetching}
              labelClassName="justify-between mr-10"
              text={<Trans>Caliper</Trans>}
            >
              <Select
                options={getCaliperOptions(brands as Brand[], {
                  selectedBrand: brandWatch,
                  selectedGrammage: grammageWatch,
                  defaultSelectLabel: t`Select`,
                  isMetric,
                })
                  .filter((o) => o.value !== 0)
                  .map(formatCaliperOption)}
                ref={register()}
                name="caliperInMils"
                disabled={!!sappiSkusValues}
              />
            </InputLabel>
          </div>
        </div>
      )}
      {!isMetric && (
        <div className="w-1/2">
          <InputLabel
            size="lg"
            isFetching={isFetching}
            labelClassName="justify-between mr-10"
            text={<Trans>Basis Weight</Trans>}
          >
            <Select
              options={getBaseWeightOptions(
                brands as Brand[],
                {
                  selectedBrand: brandWatch,
                  selectedCaliper: String(caliperWatch),
                  defaultSelectLabel: t`Select`,
                },
                false
              )}
              ref={register()}
              name="grammage"
              disabled={!!sappiSkusValues}
            />
          </InputLabel>
        </div>
      )}
      {isMetric && (
        <div className="w-1/2">
          <InputLabel
            size="lg"
            isFetching={isFetching}
            labelClassName="justify-between mr-10"
            text={<Trans>Grammage</Trans>}
          >
            <Select
              options={getAvailabilityGrammageOptions(brands as Brand[], {
                selectedBrand: brandWatch,
                selectedCaliper: String(caliperInMilsWatch),
                defaultSelectLabel: t`Select`,
              })}
              ref={register()}
              name="grammage"
              disabled={!!sappiSkusValues}
            />
          </InputLabel>
        </div>
      )}
    </>
  );

  const ReleaseUserExperience = (
    <>
      <div className="pb-3">
        <InputLabel size="lg" text={<Trans>Product</Trans>}>
          {isFetching && <LoadingSpinner className="mt-1 ml-2" />}
          <Select
            options={getParentBrandOptions(brands as Brand[], {
              selectedChemistry: chemistryWatch,
              selectedTexture: textureWatch,
              defaultSelectLabel: t`Select`,
            })}
            ref={register()}
            name="parentBrand"
          />
        </InputLabel>
      </div>
      <div className="flex pb-4">
        <div className="w-1/2">
          <InputLabel size="lg" text={<Trans>Grade</Trans>}>
            {isFetching && <LoadingSpinner className="mt-1 ml-2" />}
            <Select
              options={getGradeOptions(brands as Brand[], {
                selectedParentBrand: parentBrandWatch,
                selectedTexture: textureWatch,
                defaultSelectLabel: t`Select`,
              })}
              ref={register()}
              name="chemistry"
            />
          </InputLabel>
        </div>
        <div className="w-1/2">
          <InputLabel size="lg" text={<Trans>Texture</Trans>}>
            {isFetching && <LoadingSpinner className="mt-1 ml-2" />}
            <Select
              options={getTextureOptionsForProductAvailability(
                brands as Brand[],
                {
                  selectedParentBrand: parentBrandWatch,
                  selectedChemistry: chemistryWatch,
                  defaultSelectLabel: t`Select`,
                }
              )}
              ref={register()}
              name="texture"
            />
          </InputLabel>
        </div>
      </div>
    </>
  );

  return (
    <>
      {/* {selectedCustomerInfo && (
        <CustomerInformation selectedCustomer={selectedCustomerInfo} />
      )} */}
      <form onSubmit={onSubmit}>
        <div className="border border-gray-light400">
          <div className="border-t-4 border-blue-cobaltVariation">
            <div className="my-5 p-6 flex">
              <ProductAvailabilityFormLeftHalf>
                <div className="py-2 pb-4">
                  <InputLabel
                    size="lg"
                    required
                    className="text-gray-dark200"
                    text={t`Customer`}
                    htmlFor="product-availability-customer-select"
                  />
                  <Select
                    value={selectedCustomer}
                    options={customerOptions}
                    required
                    onChange={(e) => {
                      setSelectedCustomer(e.target.value);
                    }}
                    id="product-availability-customer-select"
                  />
                </div>
                <div className="flex flex-col md:flex-row">
                  <fieldset className="pb-3 align-top w-1/2">
                    <legend className="text-xl font-extrabold text-gray-dark200 pb-3">
                      <Trans>Filters</Trans>
                    </legend>
                    <div className="flex flex-col whitespace-normal">
                      {showStock && (
                        <ProductAvailabilityCheckboxInput
                          checked={stockChecked}
                          onChange={(checked) => {
                            setStockChecked(checked);
                          }}
                          text={t`Stock`}
                        />
                      )}
                      {!hasRole(AuthorityRole.ROLE_RELEASE) &&
                        showMillInventory && (
                          <ProductAvailabilityCheckboxInput
                            checked={millInventoryChecked}
                            onChange={(checked) => {
                              setMillInventoryChecked(checked);
                            }}
                            text={t`Mill Inventory`}
                          />
                        )}
                      {!hasRole(AuthorityRole.ROLE_RELEASE) &&
                        showPlannedProduction && (
                          <ProductAvailabilityCheckboxInput
                            checked={plannedProductionChecked}
                            onChange={(checked) => {
                              setPlannedProductionChecked(checked);
                            }}
                            text={t`Planned Production`}
                          />
                        )}
                      {!hasRole(AuthorityRole.ROLE_RELEASE) && showStock && (
                        <ProductAvailabilityCheckboxInput
                          checked={onlyAvailableItemsChecked}
                          onChange={(checked) => {
                            setOnlyAvailableItemsChecked(checked);
                          }}
                          text={t`Show only available items`}
                        />
                      )}
                    </div>
                  </fieldset>
                  {!hasRole(AuthorityRole.ROLE_RELEASE) && (
                    <fieldset className="pb-3 align-top w-1/2">
                      <legend className="text-xl font-extrabold text-gray-dark200 pb-3">
                        <Trans>Product Type</Trans>
                      </legend>
                      <div className="flex flex-col">
                        <ProductAvailabilityCheckboxInput
                          checked={rollsChecked}
                          onChange={(checked) => {
                            setRollsChecked(checked);
                          }}
                          text={t`Rolls`}
                        />
                        <ProductAvailabilityCheckboxInput
                          checked={sheetsChecked}
                          onChange={(checked) => {
                            setSheetsChecked(checked);
                          }}
                          text={t`Sheets`}
                        />
                      </div>
                    </fieldset>
                  )}
                </div>
                {hasRole(AuthorityRole.ROLE_RELEASE)
                  ? ReleaseUserExperience
                  : NonReleaseUserExperience}

                <div className="flex pt-4 items-center">
                  <Button
                    type="submit"
                    theme="primary"
                    disabled={applyFilterDisabled}
                  >
                    <Trans>Show Availability</Trans>
                  </Button>
                  <span className="px-2" />
                  <Button
                    type="reset"
                    theme="link"
                    className="text-lg font-bold"
                    onClick={onSubmitReset}
                  >
                    <Trans>Reset</Trans>
                  </Button>
                </div>

                {applyFilterDisabled && (
                  <div className="pt-5">
                    <Trans>
                      To perform a product availability search, please enter{' '}
                      <strong>at minimum</strong> one additional parameter
                    </Trans>
                  </div>
                )}
              </ProductAvailabilityFormLeftHalf>
              {!hasRole(AuthorityRole.ROLE_RELEASE) && (
                <ProductAvailabilityFormRightHalf>
                  <div className="mb-2.5">
                    <div className="mb-2.5">
                      <div className="mb-2.5">
                        <InputLabel
                          size="lg"
                          className="text-gray-dark200"
                          text={t`Enter Sappi SKU or Customer Material Number`}
                        />
                        <TagInput
                          name="sappiSkus"
                          register={register}
                          reset={reset}
                          getValues={getValues}
                          watch={watch}
                          formState={formState}
                        />
                        <div>
                          <p>
                            <Trans>
                              Enter a Sappi SKU or Customer Material Number
                              above and click the &quot;Add&quot; button to add
                              a Sappi SKU or Customer Material Number. If the
                              Sappi SKU or Customer Material Number is unknown,
                              please click on the Sappi SKU Lookup tool to find
                              it.
                            </Trans>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between mb-6">
                      {!isEuUser && (
                        <Button
                          theme="link"
                          onClick={() => setSkuLookupModalVisible(true)}
                          onKeyDown={noop}
                          className="text-lg no-underline hover:opacity-60"
                        >
                          <Trans>Sappi SKU Lookup</Trans>
                        </Button>
                      )}
                      <Button
                        theme="link"
                        onClick={clearAllSappiSkus}
                        className="ml-2"
                      >
                        <Trans>Clear All SKUs or Customer Material No.s</Trans>
                      </Button>
                    </div>
                    {!isEuUser && (
                      <div className="mb-2.5">
                        <Button
                          theme="link"
                          className="text-lg no-underline hover:opacity-60"
                          onClick={() => {
                            trackPageView(
                              'PRODUCT_AVAILABILITY',
                              'VIEW_BOOKMARKED_SKUS'
                            );
                            setBookmarkedSkusModalVisible(true);
                          }}
                        >
                          <Trans>My Bookmarked Sappi SKUs</Trans>
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="mt-8">
                    <div className="flex items-center py-3">
                      <InputLabel
                        position="side"
                        size="lg"
                        className="ml-2.5"
                        text={
                          <>
                            <Trans>Width</Trans>&nbsp;
                            {isMetric ? (
                              <Trans>(cm)</Trans>
                            ) : (
                              <Trans>(in)</Trans>
                            )}
                          </>
                        }
                      />
                      <Input
                        width={mediumWidthKwd}
                        name="minWidth"
                        maxLength={9}
                        type="string"
                        onKeyPress={fractionOrDecimalInput}
                        ref={register({
                          pattern: IMP_REG_EX,
                        })}
                        error={errors.minWidth}
                      />

                      <span className="mx-2 text-xl font-extrabold">
                        <Trans>to</Trans>
                      </span>
                      <Input
                        width={mediumWidthKwd}
                        name="maxWidth"
                        maxLength={9}
                        type="string"
                        onKeyPress={fractionOrDecimalInput}
                        ref={register({
                          pattern: IMP_REG_EX,
                        })}
                        error={errors.maxWidth}
                      />
                    </div>
                    <div className="flex items-center py-3">
                      <InputLabel
                        position="side"
                        size="lg"
                        text={
                          <>
                            <Trans>Length</Trans>&nbsp;
                            {isMetric ? (
                              <Trans>(cm)</Trans>
                            ) : (
                              <Trans>(in)</Trans>
                            )}
                          </>
                        }
                      />
                      <Input
                        width={mediumWidthKwd}
                        name="minLength"
                        maxLength={9}
                        type="string"
                        onKeyPress={fractionOrDecimalInput}
                        ref={register({
                          pattern: IMP_REG_EX,
                        })}
                        error={errors.minLength}
                      />
                      <span className="mx-2 text-xl font-extrabold">
                        <Trans>to</Trans>
                      </span>
                      <Input
                        width={mediumWidthKwd}
                        name="maxLength"
                        maxLength={9}
                        type="string"
                        onKeyPress={fractionOrDecimalInput}
                        ref={register({
                          pattern: IMP_REG_EX,
                        })}
                        error={errors.maxLength}
                      />
                    </div>
                  </div>
                </ProductAvailabilityFormRightHalf>
              )}
            </div>
          </div>
        </div>
      </form>
      <SkuLookupModal
        visible={skuLookupModalVisible}
        customer={selectedCustomer || ''}
        onCancel={() => setSkuLookupModalVisible(false)}
        updateCustomer={(customer) => {
          setSelectedCustomer(customer);
        }}
        addSappiSkus={addSappiSkus}
      />
      <BookmarkedSkusModal
        visible={bookmarkedSkusModalVisible}
        onCancel={() => setBookmarkedSkusModalVisible(false)}
        searchBySappiSkus={(sappiSkusChosen: string[]) => {
          clearAllSappiSkus();
          searchBookmarkedSkus(sappiSkusChosen);
          // This is to show chosen Sappi Skus on UI
          addSappiSkus(sappiSkusChosen);
        }}
      />
    </>
  );
};

export default ProductAvailabilityForm;
