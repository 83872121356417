import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { find } from 'lodash';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import { PARAM_FORMAT } from '../../../components/DatePicker/DatePicker';
import ExcelDownload from '../../../components/ExcelDownload/ExcelDownload';
import SearchPopover from '../../../components/SearchPopover/SearchPopover';
import { LinkResultResponse } from '../../../types/LinkResultResponse.interface';
import Option from '../../../types/Option.type';
import { Order } from '../../../types/Order.interface';
import { SearchOrderDetailsInputs } from '../SearchOrderDetailsInputs.interface';
import { SearchOrderInputs } from '../SearchOrderInputs.interface';
import TrackOrderTab from '../TrackOrderTab.enum';
import activeMenuPopoverNotificationStore from './Table/actionMenuPopoverNotification.store';
import useExpandedRowKeyStore from './Table/expandedRowKey.store';
import ViewOrdersTable from './Table/ViewOrdersTable';
import ViewOrdersSearchForm from './ViewOrdersSearchForm';
import {
  getProductOptions,
  getShipToOptions,
  getStatusOptions,
} from './ViewOrdersSearchForm.util';
import useAnalytics from '../../../hooks/useAnalytics';
import { AuthorityRole } from '../../../types/Authority.interface';
import useRole from '../../../hooks/useRole';

interface IViewOrdersProps {
  orders: LinkResultResponse<Order>;
  filters?: Partial<SearchOrderInputs>;
  onApplySearchFilters: (filters: Partial<SearchOrderInputs>) => void;
  onNewTabTrigger: (tab: TrackOrderTab, data: SearchOrderDetailsInputs) => void;
}

const ViewOrders: React.FunctionComponent<IViewOrdersProps> = ({
  orders,
  filters,
  onNewTabTrigger,
  onApplySearchFilters,
}) => {
  const { hasRole, isNonReleaseEuUser } = useRole();
  const [searchPopoverVisible, setSearchPopoverVisible] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    errors,
    trigger,
    watch,
    formState,
  } = useForm({
    mode: 'all',
  });
  const [statusOptions, setStatusOptions] = useState<Option[]>([]);
  const [productOptions, setProductOptions] = useState<Option[]>([]);
  const [shipToOptions, setShipToOptions] = useState<Option[]>([]);
  const { trackPageView } = useAnalytics();

  const { setHidePopover, hidePopover } = activeMenuPopoverNotificationStore();

  const {
    replaceAll: expandAll,
    removeAll: collapseAll,
    generateRowKey,
  } = useExpandedRowKeyStore();

  useEffect(() => {
    if (orders) {
      const { links } = orders;
      const populateOptions = async () => {
        setStatusOptions(
          await getStatusOptions(
            find(links, { rel: 'statusTexts' }),
            hasRole(AuthorityRole.ROLE_NA)
          )
        );
        setProductOptions(
          await getProductOptions(find(links, { rel: 'brands' }))
        );
        setShipToOptions(
          await getShipToOptions(find(links, { rel: 'shipTos' }))
        );
      };
      populateOptions();
    }
  }, [orders, hasRole]);

  const submitSearch = handleSubmit(
    (newFilters: Partial<SearchOrderInputs>) => {
      setSearchPopoverVisible(false);
      onApplySearchFilters({
        ...filters,
        ...{
          ...newFilters,
          minDeliveryDate: newFilters.minDeliveryDate
            ? (newFilters.minDeliveryDate as Moment).format(PARAM_FORMAT)
            : '',
          maxDeliveryDate: newFilters.maxDeliveryDate
            ? (newFilters.maxDeliveryDate as Moment).format(PARAM_FORMAT)
            : '',
        },
        page: 1,
      });
    }
  );

  const onPageChange = (page: number, pageSize: number) => {
    trackPageView('TRACK_ORDERS', 'PAGE_CHANGE');
    onApplySearchFilters({
      ...filters,
      page,
      pageSize,
    });
  };

  const toggleShowAllResults = () => {
    if (filters) {
      onApplySearchFilters({
        ...filters,
        all: !filters?.all,
        page: 1,
      });
    }
  };

  return (
    <>
      <div className="w-full flex justify-between items-center mb-5 mt-3">
        <Space>
          <Button
            theme="link"
            onClick={() => {
              trackPageView('TRACK_ORDERS', 'EXPAND_ALL');
              expandAll(orders.results.map((r) => generateRowKey(r)));
            }}
          >
            <Trans>Expand All</Trans>
          </Button>
          |
          <Button theme="link" onClick={() => {
            trackPageView('TRACK_ORDERS', 'COLLAPSE_ALL');
            collapseAll();
          }}>
            <Trans>Collapse All</Trans>
          </Button>
        </Space>
        <Space size="middle">
          <ExcelDownload params={filters || {}}>
            <i className="far fa-file-excel-o text-blue-pacific text-2xl mr-2" />
            <span className="app-link text-lg">Generate Report</span>
          </ExcelDownload>

          <div>
            <SearchPopover
              visible={searchPopoverVisible}
              placement="bottomLeft"
              title={t({
                id: 'search.inline.header',
                message: 'Search Orders',
              })}
              onClose={() => setSearchPopoverVisible(false)}
              body={
                <ViewOrdersSearchForm
                  statusOptions={statusOptions}
                  productOptions={productOptions}
                  shipToOptions={shipToOptions}
                  selectedFilters={
                    filters || ({} as Partial<SearchOrderInputs>)
                  }
                  register={register}
                  control={control}
                  getValues={getValues}
                  errors={errors}
                  trigger={trigger}
                  watch={watch}
                  labelCapitalizeTransform="allUpperCase"
                />
              }
              button={
                <Button
                  theme="primary"
                  onClick={submitSearch}
                  disabled={!formState.isValid}
                >
                  {t({
                    id: 'search.inline.submit.value',
                    message: 'Search',
                  })}
                </Button>
              }
            >
              <Button
                theme="link"
                onClick={() => setSearchPopoverVisible(true)}
              >
                <i className="fa fa-search text-xl" />
              </Button>
            </SearchPopover>
          </div>
        </Space>
      </div>
      <div className="relative">
        <ViewOrdersTable
          data={orders ? orders.results : []}
          totalResults={orders ? orders.totalResults || 0 : 0}
          onPageChange={onPageChange}
          onToggleShowAll={toggleShowAllResults}
          filters={filters}
          onNewTabTrigger={(
            tab: TrackOrderTab,
            searchOrderDetailsInputs: SearchOrderDetailsInputs
          ) => {
            setHidePopover(!hidePopover);
            onNewTabTrigger(tab, searchOrderDetailsInputs);
          }}
        />
      </div>
    </>
  );
};

export default ViewOrders;
