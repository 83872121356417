import React, { FC, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Collapse } from 'antd';
import { useQuery, useQueryCache } from 'react-query';
import { find } from 'lodash';
import { useForm } from 'react-hook-form';
import { Moment } from 'moment';
import ExpandIcon from '../../components/ExpandIcon';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { getMarkedOrders, QueryParams } from '../../services/Order';
import ViewOrdersWidgetTable from '../TrackOrders/ViewOrders/Table/ViewOrdersWidgetTable';
import { Button } from '../../components/Button/Button';
import SearchPopover from '../../components/SearchPopover/SearchPopover';
import ViewOrdersSearchForm from '../TrackOrders/ViewOrders/ViewOrdersSearchForm';
import { SearchOrderInputs } from '../TrackOrders/SearchOrderInputs.interface';
import Option from '../../types/Option.type';
import {
  getProductOptions,
  getShipToOptions,
  getStatusOptions,
} from '../TrackOrders/ViewOrders/ViewOrdersSearchForm.util';
import { PARAM_FORMAT } from '../../components/DatePicker/DatePicker';
import styles from './BookmarkedOrders.module.scss';
import './BookmarkedOrders.scss';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { saveUserCustomization } from '../../services/User';
import useUserCustomization from '../../hooks/useUserCustomization';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';

const KEY_NUMBER = '1';

interface IBookmarkedOrdersProps {
  className?: string;
}
const BookmarkedOrders: FC<IBookmarkedOrdersProps> = ({ className = '' }) => {
  const { Panel } = Collapse;
  const {
    register,
    control,
    handleSubmit,
    getValues,
    errors,
    trigger,
    watch,
    formState,
  } = useForm({
    mode: 'all',
  });

  const { data: userCustomization } = useUserCustomization();
  const { hasRole, isNonReleaseEuUser } = useRole();

  const [activeKey, setActiveKey] = useState('');
  const [filters, setFilters] = useState<Partial<SearchOrderInputs>>();
  const [searchPopoverVisible, setSearchPopoverVisible] = useState(false);
  const [statusOptions, setStatusOptions] = useState<Option[]>([]);
  const [productOptions, setProductOptions] = useState<Option[]>([]);
  const [shipToOptions, setShipToOptions] = useState<Option[]>([]);

  const { data, isFetching, refetch } = useQuery(
    [QueryCacheName.MARKED_ORDERS, (filters as QueryParams) || {}],
    () => getMarkedOrders(filters as QueryParams),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      enabled: !!filters,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setFilters({
      customerNumber: '',
      minLastRevisionDate: '',
      maxLastRevisionDate: '',
      minDeliveryDate: '',
      maxDeliveryDate: '',
      minArrivalDate: '',
      maxArrivalDate: '',
      marked: undefined,
      all: true,
      page: 1,
      pageSize: 10,
    });
  }, [activeKey]);

  useEffect(() => {
    if (data && data.results) {
      const { links } = data;
      const populateOptions = async () => {
        setStatusOptions(
          await getStatusOptions(
            find(links, { rel: 'statusTexts' }),
            hasRole(AuthorityRole.ROLE_NA)
          )
        );
        setProductOptions(
          await getProductOptions(find(links, { rel: 'brands' }))
        );
        setShipToOptions(
          await getShipToOptions(find(links, { rel: 'shipTos' }))
        );
      };
      populateOptions();
    }
  }, [data, hasRole]);

  // The flag collapseSelectedOrders === true means the collapse is closed, vice versa.
  useEffect(() => {
    if (userCustomization) {
      setActiveKey(!userCustomization.collapseSelectedOrders ? KEY_NUMBER : '');
    }
  }, [userCustomization]);

  const doSaveSettings = async (collapseSelectedOrders: boolean) => {
    if (userCustomization) {
      await saveUserCustomization({
        ...userCustomization,
        collapseSelectedOrders,
      });
    }
  };

  const submitSearch = handleSubmit(
    (newFilters: Partial<SearchOrderInputs>) => {
      setSearchPopoverVisible(false);
      setFilters((state) => ({
        ...state,
        usePaginatedVersion: true,
        ...{
          ...newFilters,
          minDeliveryDate: newFilters.minDeliveryDate
            ? (newFilters.minDeliveryDate as Moment).format(PARAM_FORMAT)
            : '',
          maxDeliveryDate: newFilters.maxDeliveryDate
            ? (newFilters.maxDeliveryDate as Moment).format(PARAM_FORMAT)
            : '',
        },
      }));
    }
  );
  return (
    <div className={`${className}`}>
      <div className="flex justify-end">
        {activeKey && (
          <div className="grid grid-cols-2 divide-x divide-white-500">
            <Button
              className="fa fa-refresh text-2xl"
              onClick={() => {
                setFilters((state) => ({
                  ...state,
                  usePaginatedVersion: undefined,
                }));
                refetch();
              }}
            />
            <SearchPopover
              visible={searchPopoverVisible}
              placement="bottomLeft"
              title={t`Search Orders`}
              className="bookmarked-order-search-popover"
              onClose={() => setSearchPopoverVisible(false)}
              body={
                <ViewOrdersSearchForm
                  isWidget
                  statusOptions={statusOptions}
                  productOptions={productOptions}
                  shipToOptions={shipToOptions}
                  selectedFilters={filters || {}}
                  register={register}
                  control={control}
                  getValues={getValues}
                  errors={errors}
                  trigger={trigger}
                  watch={watch}
                  labelCapitalizeTransform="allUpperCase"
                />
              }
              button={
                <Button
                  theme="primary"
                  onClick={submitSearch}
                  disabled={!formState.isValid}
                >
                  {t`Search`}
                </Button>
              }
            >
              <Button
                className="fa fa-search text-2xl"
                onClick={() => setSearchPopoverVisible(true)}
              />
            </SearchPopover>
          </div>
        )}
      </div>
      <Collapse
        className="sappi-collapse-custom-collapse"
        accordion
        bordered={false}
        expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}
        destroyInactivePanel
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key as string);
          doSaveSettings((key as string) !== KEY_NUMBER); // The flag collapseSelectedOrders === true means the collapse is closed, vice versa.
        }}
      >
        <Panel
          header={
            <div className="text-3xl font-bold -mt-2">
              <Trans>Bookmarked Orders</Trans>
            </div>
          }
          key={KEY_NUMBER}
          className={`sappi-collapse-custom-summary-collapse ${styles.bookmarkedOrder}`}
        >
          <div style={{ maxHeight: '620px' }} className="overflow-auto">
            {isFetching ? (
              <div style={{ height: '240px' }}>
                <LoadingSpinner horizontal />
              </div>
            ) : (
              <ViewOrdersWidgetTable data={data?.results || []} />
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default BookmarkedOrders;
