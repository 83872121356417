import Moment from 'moment';
import useLanguage from './useLanguage';

export enum DateFormat {
  BASIC = 'MMM DD, YYYY',
  BASIC_WITH_TIME = 'MMM DD, YYYY HH:mm',
  BASIC_NUM = 'MM/DD/YYYY',
  EU_BASIC_NUM = 'DD/MM/YYYY',
  BASIC_TIME = 'HH:mm',
  EU_SIMPLE = 'DDMMYYYY',
  NA_SIMPLE = 'MMDDYYYY',
  EU_NEWS = 'DD MMMM YYYY',
  NA_NEWS = 'MMMM DD, YYYY',
}

const useDateFormatter = (): {
  format: (
    date: number | Date | string,
    format?: DateFormat,
    useLocalTimezone?: boolean
  ) => string;
} => {
  const lang = useLanguage();
  const formatDate = (
    date: number | Date | string,
    format?: DateFormat,
    useLocalTimezone?: boolean
  ): string => {
    return useLocalTimezone
      ? Moment(date)
          .locale(lang)
          .format(format || DateFormat.BASIC)
      : Moment(date)
          .locale(lang)
          .utc()
          .format(format || DateFormat.BASIC);
  };
  return {
    format: formatDate,
  };
};

export default useDateFormatter;
