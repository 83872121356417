import { find, isNumber, startCase, upperCase } from 'lodash';
import * as React from 'react';
import { Controller, FieldError, UseFormMethods } from 'react-hook-form';
import { Select as AntdSelect } from 'antd';
import useDateFormatter from '../../hooks/useDateFormatter';
import InputWrapper, {
  calculateInputWrapperWidth,
} from '../InputWrapper/InputWrapper';
import './ControlledSelectMulti.scss';

export type AntdSelectOption = {
  key?: string;
  title?: string;
  label: string | React.ReactNode;
  value?: string | number | undefined;
  disabled?: boolean;
};

export type LabelTransformMode = 'capitalize' | 'allUpperCase' | undefined;

export interface IAntdSelectProps {
  control: UseFormMethods['control'];
  getValues?: UseFormMethods['getValues'];
  name: string;
  options: AntdSelectOption[] | undefined;
  omitBoxOutline?: boolean;
  defaultValue: string[];
  disabled?: boolean;
  width?: string;
  className?: string;
  dateSelect?: boolean;
  error?: { message: string } | FieldError | undefined;
  allowClear?: boolean;
  labelCapitalizeTransform?: LabelTransformMode;
  placeholder?: string;
}

const { Option: SelectOption } = AntdSelect;

const ControlledSelectMulti: React.FunctionComponent<IAntdSelectProps> = ({
  control,
  getValues,
  name,
  options,
  omitBoxOutline,
  defaultValue,
  disabled,
  width,
  className,
  dateSelect,
  error,
  allowClear = true,
  labelCapitalizeTransform,
  placeholder,
}) => {
  const { format } = useDateFormatter();

  const getLabelTransform = (string: string) => {
    switch (labelCapitalizeTransform) {
      case 'allUpperCase':
        return upperCase(string);
      case 'capitalize':
        return startCase(string);
      default:
        return string;
    }
  };

  return (
    <>
      <InputWrapper
        omitBoxOutline={omitBoxOutline}
        error={error}
        width={calculateInputWrapperWidth(width)}
      >
        <span
          className={`dropdown-icon ${
            disabled ? 'dropdown-icon--disabled' : ''
          } relative`}
        >
          <Controller
            name={name}
            defaultValue={defaultValue}
            control={control}
            render={(rest) => (
              <AntdSelect
                {...rest}
                placeholder={placeholder}
                mode="multiple"
                allowClear={allowClear}
                showSearch={false}
                disabled={disabled}
                className={`${className || ''} ant-multi-select`}
                onChange={(v: any) => {
                  rest.onChange(v); // Update the form state with the selected values
                }}
                dropdownRender={(optionDropdown) => <>{optionDropdown}</>}
              >
                {options
                  ? options
                      .filter((o) => !!o.value)
                      .map((option: AntdSelectOption) => (
                        <SelectOption
                          key={option.key || (option.value as string)}
                          value={option.value as string | number}
                          label={option.label}
                          disabled={option.disabled}
                        >
                          {dateSelect && isNumber(option.label)
                            ? format(option.label as number)
                            : `${getLabelTransform(option.label as string)}`}
                        </SelectOption>
                      ))
                  : null}
              </AntdSelect>
            )}
          />
        </span>
      </InputWrapper>
    </>
  );
};

export default ControlledSelectMulti;
