import { t, Trans } from '@lingui/macro';
import { Popover } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQueryCache } from 'react-query';
import AvailableActionsButton from '../../../../components/AvailableActionsButton/AvailableActionsButton';
import Bookmark from '../../../../components/Bookmark/Bookmark';
import { Button } from '../../../../components/Button/Button';
import Callout from '../../../../components/Callout/Callout';
import ShippingInformationModal from '../../../../components/ShippingInformationModal/ShippingInformationModal';
import FirstColumnCell from '../../../../components/Table/FirstColumnCell';
import Table from '../../../../components/Table/Table';
import useUserCustomization from '../../../../hooks/useUserCustomization';
import showNotification from '../../../../services/Notification';
import { togglePriorityFlagFromOrder } from '../../../../services/PriorityFlag';
import { saveUserCustomization } from '../../../../services/User';
import { Order } from '../../../../types/Order.interface';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { UserCustomization } from '../../../../types/User/UserCustomization.interface';
import { SearchOrderDetailsInputs } from '../../SearchOrderDetailsInputs.interface';
import { SearchOrderInputs } from '../../SearchOrderInputs.interface';
import TrackOrderTab from '../../TrackOrderTab.enum';
import activeMenuPopoverNotificationStore from './actionMenuPopoverNotification.store';
import ActionsMenuPopover from './ActionsMenuPopover';
import useExpandedRowKeyStore from './expandedRowKey.store';
import useAnalytics from '../../../../hooks/useAnalytics';
import { ViewOrdersRow } from './ViewOrdersRow.interface';
import { isBookmarked } from './viewOrdersTableCell.util';
import {
  confirmedDeliveryDateColumn,
  productColumn,
  shipToColumn,
  skuColumn,
  stateColumn,
  stateTranslatedColumn,
} from './viewOrdersTableColumns.util';
import useViewOrdersRow from './useViewOrderRows';
import OrderCell from './OrderCell';
import useRole from '../../../../hooks/useRole';
import { AuthorityRole } from '../../../../types/Authority.interface';

interface IViewOrdersTableProps {
  data: Order[];
  filters?: Partial<SearchOrderInputs>;
  onNewTabTrigger: (
    tab: TrackOrderTab,
    searchOrderDetailsInputs: SearchOrderDetailsInputs
  ) => void;
  loading?: boolean;
  totalResults: number;
  onPageChange: (page: number, pageSize: number) => void;
  onToggleShowAll: () => void;
}

const PAGE_SIZE = 10;
const SHOW_ALL_PAGE_SIZE = 10000;
const STATUS_THRESHOLD_FOR_SHIPPING_INFO = 40;

const ViewOrdersTable: React.FunctionComponent<IViewOrdersTableProps> = ({
  data,
  filters,
  onNewTabTrigger,
  loading,
  totalResults,
  onPageChange,
  onToggleShowAll,
}) => {
  const { hasRole } = useRole();
  const { viewOrderRows } = useViewOrdersRow(data);
  const { trackPageView } = useAnalytics();
  const onChange = (page: number, pageSize?: number) => {
    onPageChange(page, pageSize || PAGE_SIZE);
  };
  const [tableData, setTableData] = useState<ViewOrdersRow[]>();
  const [showCallout, setShowCallout] = useState<boolean>(false);

  const { hidePopover } = activeMenuPopoverNotificationStore();
  const [
    currentAvailableActionsPopoverVisible,
    setCurrentAvailableActionsPopoverVisible,
  ] = useState<string | null>();

  const [deliveryDetailsOrder, setDeliveryDetailsOrder] = useState<Order>();
  const [
    deliveryDetailsModalVisible,
    setDeliveryDetailsModalVisible,
  ] = useState<boolean>(false);
  const { data: userCustomization } = useUserCustomization();
  const queryCache = useQueryCache();
  const {
    keys: expandedRowKeys,
    add: addRowKey,
    remove: removeRowKey,
    generateRowKey,
  } = useExpandedRowKeyStore();

  useEffect(() => {
    setCurrentAvailableActionsPopoverVisible(null);
  }, [hidePopover]);

  useEffect(() => {
    setTableData(viewOrderRows);
  }, [viewOrderRows]);

  useEffect(() => {
    if (userCustomization && !userCustomization.hideBookmarksCallout) {
      setShowCallout(true);
    }
  }, [userCustomization]);

  const updateUserBookmarkCallout = async () => {
    setShowCallout(false);
    await saveUserCustomization({
      ...userCustomization,
      hideBookmarksCallout: true,
    } as UserCustomization);
    queryCache.invalidateQueries(QueryCacheName.USER_CUSTOMIZATION);
  };

  const toggleBookmark = async (record: ViewOrdersRow) => {
    trackPageView('TRACK_ORDERS', 'TOGGLE_BOOKMARK');
    const overviews = await togglePriorityFlagFromOrder(record);
    if (tableData) {
      setTableData(
        tableData.map((row) => {
          if (row.key === record.key) {
            row.overviews = overviews;
          }
          return row;
        })
      );
    }
    const notificationTxt = `${t({
      id: 'yourOrder',
      message: 'Your Order #',
    })} ${record.documentNumber} ${t({
      id: 'order_tracking.bookmark.confirm_message',
      message: 'was added to your Bookmarked Orders on the Dashboard',
    })}`;
    if (isBookmarked(record)) {
      showNotification({
        message: '',
        description: notificationTxt,
      });
    }
    queryCache.invalidateQueries([QueryCacheName.ORDERS, filters], {
      refetchActive: false,
    });
  };

  const showShippingDetailsModal = (record: ViewOrdersRow) => {
    setDeliveryDetailsModalVisible(true);
    setDeliveryDetailsOrder(record);
  };

  const onShippingDetailsClose = () => {
    setDeliveryDetailsModalVisible(false);
  };

  const isCurrentAvailableActionsPopoverVisible = (
    record: ViewOrdersRow
  ): string | null => {
    trackPageView('TRACK_ORDERS', 'AVAILABLE_ACTIONS', {
      customerId: record.overviews[0].customerNumber,
    });
    return currentAvailableActionsPopoverVisible &&
      currentAvailableActionsPopoverVisible ===
        record.documentNumber + record.overviews[0].documentPosition
      ? null
      : record.documentNumber + record.overviews[0].documentPosition;
  };

  const columns: ColumnsType<ViewOrdersRow> = [
    {
      title: (
        <Callout
          title={<Trans>Bookmarks</Trans>}
          body={
            <Trans>
              Select the bookmark symbol to boomark an order and view it on your
              dashboard
            </Trans>
          }
          onClose={updateUserBookmarkCallout}
          visible={showCallout}
          placement="right"
        >
          <Bookmark solid inheritColor />
        </Callout>
      ),
      dataIndex: 'bookmark',
      key: 'bookmark',
      width: '78px',
      align: 'center',
      render: (text: string, record: ViewOrdersRow) => {
        return (
          <FirstColumnCell isChildRow={record.isChild || false}>
            <Bookmark
              solid={isBookmarked(record)}
              onClick={() => toggleBookmark(record)}
            />
          </FirstColumnCell>
        );
      },
      sorter: (a: ViewOrdersRow, b: ViewOrdersRow) =>
        isBookmarked(a) ? 1 : -1,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <Trans>Order</Trans>,
      dataIndex: 'order',
      key: 'order',
      render: (text: string, record: ViewOrdersRow) => (
        <OrderCell record={record} onLinkClick={onNewTabTrigger} />
      ),
      sorter: (a: ViewOrdersRow, b: ViewOrdersRow) =>
        a.customerOrderNumber > b.customerOrderNumber ? 1 : -1,
      sortDirections: ['descend', 'ascend'],
    },
    stateTranslatedColumn(hasRole(AuthorityRole.ROLE_NA)),
    {
      title: <i className="fa fa-truck" />,
      dataIndex: 'shippingInformation',
      key: 'shippingInformation',
      render: (text: string, record: ViewOrdersRow): JSX.Element => (
        <>
          {parseInt(record.overviews[0].status, 10) >=
          STATUS_THRESHOLD_FOR_SHIPPING_INFO ? (
            <Button
              theme="link"
              onClick={() => showShippingDetailsModal(record)}
              title={`${t({
                id: 'order_list.group_order.transit_info',
                message: 'Transit information for order #',
              })}
      ${record.customerOrderNumber}`}
            >
              <i className="fa fa-truck" />
            </Button>
          ) : (
            <i className="fa fa-truck" />
          )}
        </>
      ),
      align: 'center',
    },
    productColumn,
    skuColumn,
    confirmedDeliveryDateColumn,
    shipToColumn,
    {
      title: <Trans>Actions</Trans>,
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: ViewOrdersRow) => {
        return record.overviews.length === 1 ? (
          <Popover
            placement="leftTop"
            visible={
              currentAvailableActionsPopoverVisible ===
              record.documentNumber + record.overviews[0].documentPosition
            }
            content={
              <ActionsMenuPopover
                onNewTabTrigger={onNewTabTrigger}
                order={record}
                onClick={() => setCurrentAvailableActionsPopoverVisible(null)}
              />
            }
          >
            <AvailableActionsButton
              text={<Trans id="available_actions">Available Actions</Trans>}
              onClick={() => {
                setCurrentAvailableActionsPopoverVisible(
                  isCurrentAvailableActionsPopoverVisible(record)
                );
              }}
            />
          </Popover>
        ) : (
          <Trans id="order_tracking.action.group_message">
            Expand to view available actions
          </Trans>
        );
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={tableData}
        loading={loading}
        columns={columns}
        rowKey={(record: ViewOrdersRow) => generateRowKey(record)}
        pagination={{
          current: filters?.page,
          onChange,
          pageSize: filters?.all ? SHOW_ALL_PAGE_SIZE : PAGE_SIZE,
          position: ['topRight'],
          total: totalResults,
          showSizeChanger: false,
        }}
        config={{
          showAllOption: true,
        }}
        isShowAll={!filters?.all}
        onToggleShowAll={onToggleShowAll}
        expandable={{
          rowExpandable: (record) => record.overviews.length > 1,
          expandedRowKeys,
          onExpand: (expanded, record: ViewOrdersRow) =>
            expanded
              ? addRowKey(generateRowKey(record))
              : removeRowKey(generateRowKey(record)),
        }}
        locale={{
          emptyText: (
            <div className="text-gray-600 my-3">
              <Trans id="order_list.empty">No orders found</Trans>
            </div>
          ),
        }}
      />
      <ShippingInformationModal
        visible={deliveryDetailsModalVisible}
        customerNumber={get(deliveryDetailsOrder, 'customerNumber', '')}
        shipToPartyName={get(
          deliveryDetailsOrder,
          'overviews[0].shipToPartyName',
          ''
        )}
        shipToPartyCity={get(
          deliveryDetailsOrder,
          'overviews[0].shipToPartyCity',
          ''
        )}
        documentNumber={get(deliveryDetailsOrder, 'documentNumber', '')}
        customerOrderNumber={get(
          deliveryDetailsOrder,
          'customerOrderNumber',
          ''
        )}
        overviews={deliveryDetailsOrder?.overviews || []}
        onClose={onShippingDetailsClose}
      />
    </>
  );
};

export default ViewOrdersTable;
