import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import ControlledDatePicker from '../../../components/DatePicker/ControlledDatePicker';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import Option from '../../../types/Option.type';
import { SearchOrderInputs } from '../SearchOrderInputs.interface';
import useCorrectMomentFormat from '../../../hooks/useCorrectMomentFormat';
import useRole from '../../../hooks/useRole';
import ControlledSelectMulti, {
  AntdSelectOption,
  LabelTransformMode,
} from '../../../components/ControlledSelectMulti/ControlledSelectMulti';

interface IViewOrdersSearchFormProps {
  isWidget?: boolean;
  labelCapitalizeTransform?: LabelTransformMode;
  register: UseFormMethods['register'];
  control: UseFormMethods['control'];
  trigger: UseFormMethods['trigger'];
  statusOptions: Option[];
  productOptions: Option[];
  shipToOptions: Option[];
  selectedFilters: Partial<SearchOrderInputs>;
  getValues: UseFormMethods['getValues'];
  errors: UseFormMethods['errors'];
  watch: UseFormMethods['watch'];
}

const ViewOrdersSearchForm: React.FunctionComponent<IViewOrdersSearchFormProps> = ({
  isWidget = false,
  labelCapitalizeTransform,
  register,
  control,
  statusOptions,
  productOptions,
  shipToOptions,
  selectedFilters,
  getValues,
  errors,
  trigger,
  watch,
}) => {
  const { hasRole } = useRole();
  const [marked, setMarked] = useState(false);
  const { momentFormat } = useCorrectMomentFormat();
  const isMinDeliveryDateAfterMax = (maxDeliveryDate: Moment) => {
    const minDeliveryDate = getValues('minDeliveryDate');
    if (minDeliveryDate) {
      return !(minDeliveryDate as Moment).isAfter(maxDeliveryDate);
    }
    return true;
  };
  const getMinDeliveryDate = () => {
    const currentMinDeliveryDate = getValues('minDeliveryDate');
    if (currentMinDeliveryDate) {
      return momentFormat(currentMinDeliveryDate as Moment);
    }
    return '';
  };
  const minDeliveryDateWatch = watch('minDeliveryDate');

  useEffect(() => {
    trigger('maxDeliveryDate');
  }, [minDeliveryDateWatch, trigger]);

  useEffect(() => {
    setMarked(!!selectedFilters.marked);
  }, [selectedFilters.marked]);

  return (
    <form>
      <Space size="large" className="flex items-end flex-wrap">
        {!isWidget && (
          <Button
            theme="link"
            onClick={(e) => {
              e.preventDefault();
              setMarked((state) => !state);
            }}
          >
            <i
              className={`fa fa-bookmark text-white-white text-5xl ${
                marked ? 'text-green-lime' : ''
              }`}
            />
            <input
              type="checkbox"
              ref={register}
              name="marked"
              checked={marked}
              className="hidden"
            />
          </Button>
        )}
        <InputLabel
          size="lg"
          text={t({
            id: 'search.inline.customerOrderNumber.label',
            message: 'Order #',
          })}
        >
          <Input
            name="customerOrderNumber"
            ref={register}
            defaultValue={selectedFilters.customerOrderNumber}
          />
        </InputLabel>
        <InputLabel
          size="lg"
          text={t({
            id: 'search.inline.status.label',
            message: 'Status',
          })}
        >
          <ControlledSelectMulti
            name="statusTexts"
            control={control}
            getValues={getValues}
            defaultValue={selectedFilters.statusTexts || []}
            width="auto"
            className="w-52"
            options={statusOptions as AntdSelectOption[]}
            labelCapitalizeTransform={labelCapitalizeTransform}
            placeholder={t`Any`}
          />
        </InputLabel>
        <InputLabel
          size="lg"
          text={t({
            id: 'search.inline.product.label',
            message: 'Product',
          })}
        >
          <Select
            name="paperBrand"
            width="auto"
            ref={register}
            className="w-52"
            options={productOptions}
            defaultValue={selectedFilters.paperBrand}
          />
        </InputLabel>
        <InputLabel
          size="lg"
          text={t({
            id: 'search.inline.deliveryDate.label',
            message: 'Confirmed Delivery Date',
          })}
        >
          <Space>
            <ControlledDatePicker
              control={control}
              name="minDeliveryDate"
              defaultValue={
                selectedFilters.minDeliveryDate
                  ? moment(selectedFilters.minDeliveryDate)
                  : null
              }
            />
            <div>
              <Trans id="search.to">to</Trans>
            </div>
            <ControlledDatePicker
              control={control}
              name="maxDeliveryDate"
              defaultValue={
                selectedFilters.maxDeliveryDate
                  ? moment(selectedFilters.maxDeliveryDate)
                  : null
              }
              rules={{ validate: isMinDeliveryDateAfterMax }}
              hasError={errors.maxDeliveryDate}
              toolTipErrorMsg={
                <div>
                  <Trans>Date must be after</Trans> &nbsp;
                  {getMinDeliveryDate()}
                </div>
              }
            />
          </Space>
        </InputLabel>
        <InputLabel
          size="lg"
          text={t({
            id: 'search.inline.destination.label',
            message: 'Ship To',
          })}
        >
          <Select
            name="shipToCustomerNumber"
            width="auto"
            ref={register}
            className="w-52"
            options={shipToOptions}
            defaultValue={selectedFilters.shipToCustomerNumber}
          />
        </InputLabel>
      </Space>
    </form>
  );
};

export default ViewOrdersSearchForm;
