import { Space } from 'antd';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import useDateFormatter, { DateFormat } from '../../hooks/useDateFormatter';
import useNewsItem from './useNewsItem';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';

const News: FC = () => {
  const { hasRole } = useRole();
  const { newsId } = useParams<{ newsId: string }>();
  const { newsItem } = useNewsItem(newsId);
  const { format } = useDateFormatter();

  return (
    <Space direction="vertical" size="middle">
      <i className="-mt-10">
        {format(
          newsItem?.publishDate || '',
          hasRole(AuthorityRole.ROLE_NA)
            ? DateFormat.NA_NEWS
            : DateFormat.EU_NEWS
        )}
      </i>

      <div
        className="text-xl"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: newsItem?.body || '' }}
      />
    </Space>
  );
};

export default News;
