import { Space } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowRight from '../../../assets/Arrow-Thin-Right.svg';
import useDateFormatter, { DateFormat } from '../../../hooks/useDateFormatter';
import { RecentNewsContent } from '../../../types/RecentNewsContent.interface';
import styles from './NewsItem.module.scss';
import useAnalytics from '../../../hooks/useAnalytics';
import useRole from '../../../hooks/useRole';
import { AuthorityRole } from '../../../types/Authority.interface';

interface INewsItemProps {
  item: RecentNewsContent;
}

const NewsItem: React.FunctionComponent<INewsItemProps> = ({ item }) => {
  const { hasRole } = useRole();
  const { format } = useDateFormatter();
  const history = useHistory();
  const { trackPageView } = useAnalytics();
  const goToNewsItem = () => {
    console.log(`Track Page View NEWS CLICKARTICLE`);
    trackPageView('NEWS', 'CLICK_ARTICLE');
    history.push(`/news/${item.id}`);
  };
  return (
    <Space direction="vertical" size="small" className="text-lg w-full">
      <button
        onClick={() => goToNewsItem()}
        className="flex w-full items-baseline text-left justify-between"
      >
        <span
          className="text-xl font-bold mb-4 hover:underline"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: item.title }}
        />
        <img
          src={ArrowRight}
          alt=""
          className="w-2 relative"
          style={{ top: '3px' }}
        />
      </button>
      <div>
        {format(
          item.publishDate,
          hasRole(AuthorityRole.ROLE_NA) ? DateFormat.NA_NEWS : DateFormat.EU_NEWS
        )}
      </div>

      <div
        className={styles.summary}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: item.body }}
      />
    </Space>
  );
};

export default NewsItem;
